import * as React from "react"
import { Helmet } from "react-helmet"
import '../styles/mint.css'
const ClaimWrapper = React.lazy(() =>
  import("../components/claim/wrapper.js")
)

const TempClaimPage = () => {
  const isSSR = typeof window === "undefined"
  return (
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <title>DROP 001 COLLECTION CLAIM - CULT&RAIN&trade;</title>
          <link rel="canonical" href="https://www.cultandrain.com/claim-drop-001" />
          <meta name="description" content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution." />
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous" />
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
        </Helmet>
        <div className="claim-page">
        {!isSSR && (
            <React.Suspense fallback={<div />}>
                <ClaimWrapper />
            </React.Suspense>
        )}
        </div>
      </main>
  )
}

export default TempClaimPage
